<script lang="ts" setup>
import { GoogleMap, Marker } from 'vue3-google-map'

const center = { lat: 41.7804166, lng: 44.7773194 }
const page = reactive({
  isMainPage: false,
  isBlogPage: false,
})
const route = useRoute()
watch(
  () => route.path,
  (path) => {
    page.isMainPage = path === '/en' || path === '/ka'
    page.isBlogPage = path.includes('/news')
  },
  { deep: true, immediate: true }
)
const isMainPage = computed(() => {
  return page.isMainPage
})

const isBlogPage = computed(() => {
  return route.path.includes('/news')
})

const socials = [
  {
    id: 1,
    name: 'facebook',
    link: 'https://www.facebook.com/newvisionuniv',
    icon: '/icons/facebook.svg',
  },

  {
    id: 3,
    name: 'linkedin',
    link: 'https://linkedin.com/company/new-vision-university',
    icon: '/icons/linkedin.svg',
  },
  {
    id: 4,
    name: 'instagram',
    link: 'https://www.instagram.com/newvision.ge/',
    icon: '/icons/instagram.svg',
  },
]
const contacts = [
  {
    id: 'contact-1',
    icon: '/icons/phone.svg',
    text: '+995 32 24 24 440',
    type: 'phone',
  },
  {
    id: 'contact-2',
    icon: '/icons/email.svg',
    text: 'info@newvision.ge',
    type: 'email',
  },
  {
    id: 'contact-3',
    icon: '/icons/pin.svg',
    text: '11, Nodar Bokhua Str, 0159, Tbilisi',
    type: 'address',
  },
]

const menus = [
  {
    id: 'menu-1',
    title: 'Menu Name',
    children: [
      {
        title: 'Library',
        to: '/library',
      },
      {
        title: 'FAQ',
        to: '/FAQ',
      },
      {
        title: 'Menu 2',
        to: '#',
      },
      {
        title: 'Menu 2',
        to: '#',
      },
    ],
  },
  {
    id: 'menu-2',
    title: 'Menu Name',
    children: [
      {
        title: 'Menu 2',
        to: '#',
      },
      {
        title: 'Menu 2',
        to: '#',
      },
      {
        title: 'Menu 2',
        to: '#',
      },
      {
        title: 'Menu 2',
        to: '#',
      },
    ],
  },
]

const openNativeMap = () => {
  const url = `https://www.google.com/maps/search/?api=1&query=${center.lat},${center.lng}`
  window.open(url, '_blank')
}
</script>

<template>
  <footer
    :class="{
      'snap-none md:snap-center': isMainPage,
      'mt-36': !isBlogPage,
    }"
    class="py-11 bg-prussianBlue-950"
  >
    <div
      class="flex justify-between flex-wrap max-w-[1920px] px-8 md:px-32 max-w-[1920px] mx-auto md:py-4 mx-auto"
    >
      <div class="flex flex-wrap justify-between w-full gap-x-16">
        <div>
          <img
            alt="NewVision University logo"
            src="/images/university-logo-white.svg"
          />
          <div class="my-6">
            <span
              class="capitalize text-white font-notoSansGeorgian text-base font-medium"
            >
              {{ $t('inspire to care') }}
            </span>
          </div>
          <nav role="navigation">
            <ul class="flex gap-x-[40px]">
              <li v-for="social in socials" :key="social.id">
                <a :href="social.link">
                  <img :alt="social.name" :src="social.icon" />
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <div
          title="Contact information"
          class="flex text-base font-medium mt-6 md:mt-0 font-notoSansGeorgian flex-col gap-y-6"
        >
          <div class="flex flex-col h-full justify-between gap-y-6">
            <div
              v-for="contact in contacts"
              :key="contact.id"
              class="flex items-center gap-x-4"
            >
              <img :alt="contact.type" :src="contact.icon" />
              <template v-if="contact.type === 'phone'">
                <a :href="`tel:${contact.text}`" class="text-white font-normal">
                  {{ contact.text }}
                </a>
              </template>
              <template v-else-if="contact.type === 'email'">
                <a
                  :href="`mailto:${contact.text}`"
                  class="text-white font-normal"
                >
                  {{ contact.text }}
                </a>
              </template>
              <template v-else>
                <a
                  :href="`https://maps.app.goo.gl/fptE21GC3c5uGW5F9`"
                  class="text-white font-normal"
                  target="_blank"
                >
                  {{ contact.text }}
                </a>
              </template>
            </div>
          </div>
        </div>
        <div
          class="h-[150px] w-[250px] mt-16 lg:mt-0 rounded-lg overflow-hidden"
        >
          <GoogleMap
            api-key="AIzaSyBtVvid9ctcXdWUPpfiDm0kDm1QEiDc55Y"
            style="width: 100%; height: 150px"
            :center="center"
            :zoom="15"
            @click="openNativeMap"
          >
            <Marker :options="{ position: center }" />
          </GoogleMap>
<!--          <iframe-->
<!--            width="600"-->
<!--            height="450"-->
<!--            style="border:0"-->
<!--            loading="lazy"-->
<!--            allowfullscreen-->
<!--            referrerpolicy="no-referrer-when-downgrade"-->
<!--            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBtVvid9ctcXdWUPpfiDm0kDm1QEiDc55Y&q=New+Vision+University+Eco+Campus">-->
<!--          </iframe>-->

        </div>
        <!--        <div-->
        <!--          class="flex gap-x-16 flex-col md:flex-row gap-y-16 md:gap-y-0 mt-16 md:mt-0"-->
        <!--        >-->
        <!--          <div v-for="menu in menus" :key="menu.id" class="gap-y-6">-->
        <!--            <span-->
        <!--              class="text-gray-300 font-notoSansGeorgian text-base font-medium"-->
        <!--              >{{ menu.title }}</span-->
        <!--            >-->
        <!--            <ul class="flex flex-col gap-y-6 mt-6">-->
        <!--              <li-->
        <!--                v-for="item in menu.children"-->
        <!--                :key="item.title"-->
        <!--                class="text-white font-normal"-->
        <!--              >-->
        <!--                <nuxt-link :to="item.to">{{ item.title }}</nuxt-link>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--          <img-->
        <!--            src="/images/footer/map.svg"-->
        <!--            class="mt-6 rounded-md"-->
        <!--            alt="Small city picture"-->
        <!--          />-->
      </div>
    </div>

    <!--    <div-->
    <!--      class="hidden md:flex relative overflow-hidden justify-between mt-28 items-center font-notoSansGeorgian p-8 bg-newVisionBlue-950 rounded-lg"-->
    <!--    >-->
    <!--      <div class="text-white">-->
    <!--        <span class="block text-2xl font-medium"-->
    <!--          >Become a new member of our family</span-->
    <!--        >-->
    <!--        <span class="block mt-6 text-base font-normal text-gray-300"-->
    <!--          >The University inspires individuals to advance and share knowledge,-->
    <!--          skills and values to contribute to the well-being of society.</span-->
    <!--        >-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <a href="#apply" class="bg-white rounded px-[52px] py-3">-->
    <!--          <span class="font-normal font-notoSansGeorgian">Apply</span>-->
    <!--        </a>-->
    <!--      </div>-->
    <!--      <img-->
    <!--        src="/images/footer/nvu-spacer.svg"-->
    <!--        role="presentation"-->
    <!--        alt=""-->
    <!--        class="absolute -right-[20px]"-->
    <!--      />-->
    <!--    </div>-->
  </footer>
</template>
